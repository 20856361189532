const IconCheck = () => (
  <svg width={24} height={24} viewBox="0 0 24 24">
    <path
      d="M9.45001 17.8501L3.85001 12.2501L4.92501 11.1751L9.45001 15.7001L19.05 6.1001L20.125 7.1751L9.45001 17.8501Z"
      fill="currentColor"
    />
  </svg>
);

export default IconCheck;
