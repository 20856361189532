import { useState } from "react";
import { useLanguage } from "../../contexts/LanguageContext";

interface SliderProps {
  changeFn: (eventTargetValue: string) => void;
  value: number;
  minValue: number;
  maxValue: number;
}

const Slider = ({ changeFn, value, minValue, maxValue }: SliderProps) => {
  const { messages } = useLanguage();

  const [currentValue, setCurrentValue] = useState(String(value));
  return (
    <div>
      <input
        onMouseUp={() => changeFn(currentValue)}
        onChange={(event) => setCurrentValue(event.target.value)}
        type="range"
        min={minValue}
        max={maxValue}
        value={currentValue}
        className="slider"
      />
      <div style={{ marginTop: "4px" }}>
        {Number(currentValue) >= 0
          ? messages.raiseMoreThan
          : messages.decreaseLessThan}{" "}
        <span>{currentValue}</span>%
      </div>
    </div>
  );
};

export default Slider;
