const IconReset = () => (
  <svg width={20} height={20} viewBox="0 0 48 48">
    <polyline
      className="cls-1"
      points="20 19 3 19 3 2"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="3px"
    />
    <path
      className="cls-1"
      d="M3.6,19C5.84,9.82,14.13,3,24,3c11.6,0,21,9.4,21,21s-9.4,21-21,21c-8.38,0-15.61-4.91-18.98-12"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="3px"
    />
  </svg>
);

export default IconReset;
