interface ButtonProps {
  type: "primary" | "secondary";
  caption: string;
  clickFn: () => void;
  actionType?: "warning" | "action";
  disabled?: boolean;
}

const Button = ({
  type,
  caption,
  clickFn,
  actionType,
  disabled,
}: ButtonProps) => {
  return (
    <button
      style={{ marginRight: "8px" }}
      disabled={disabled}
      className={`${
        type === "primary" ? "primary-button" : "secondary-button"
      } ${
        actionType
          ? actionType === "action"
            ? "action-button-color"
            : "warning-button-color"
          : ""
      }`}
      onClick={clickFn}
    >
      {caption}
    </button>
  );
};

export default Button;
