const IconWorkingHour = () => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <path
      d="M2.33337 13.9999C2.06671 13.9999 1.83337 13.8999 1.63337 13.6999C1.43337 13.4999 1.33337 13.2666 1.33337 12.9999V4.78325C1.33337 4.51659 1.43337 4.28325 1.63337 4.08325C1.83337 3.88325 2.06671 3.78325 2.33337 3.78325H5.50004V2.33325C5.50004 2.0777 5.60004 1.84714 5.80004 1.64159C6.00004 1.43603 6.23337 1.33325 6.50004 1.33325H9.50004C9.76671 1.33325 10 1.43603 10.2 1.64159C10.4 1.84714 10.5 2.0777 10.5 2.33325V3.78325H13.6667C13.9334 3.78325 14.1667 3.88325 14.3667 4.08325C14.5667 4.28325 14.6667 4.51659 14.6667 4.78325V8.74992C14.5112 8.6277 14.3528 8.51659 14.1917 8.41659C14.0306 8.31659 13.8556 8.22214 13.6667 8.13325V4.78325H2.33337V13.0083V12.9999H7.90762C7.94701 13.1777 8.00004 13.3499 8.06671 13.5166C8.13337 13.6833 8.21115 13.8444 8.30004 13.9999H2.33337ZM6.50004 3.78325H9.50004V2.33325H6.50004V3.78325ZM12 15.2166C11.1223 15.2166 10.3667 14.8999 9.73337 14.2666C9.10004 13.6333 8.78337 12.8777 8.78337 11.9999C8.78337 11.1221 9.10004 10.3666 9.73337 9.73325C10.3667 9.09992 11.1223 8.78325 12 8.78325C12.8778 8.78325 13.6334 9.09992 14.2667 9.73325C14.9 10.3666 15.2167 11.1221 15.2167 11.9999C15.2167 12.8777 14.9 13.6333 14.2667 14.2666C13.6334 14.8999 12.8778 15.2166 12 15.2166ZM13.2334 13.6833L13.6834 13.2333L12.2834 11.9666V9.89992H11.5834V12.1629L13.2334 13.6833Z"
      fill="currentColor"
    />
  </svg>
);

export default IconWorkingHour;
