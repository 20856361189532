import { Dispatch, SetStateAction, useState } from "react";
import EmployeeCard from "./EmployeeCard";
import { Filter } from "../../types/Filter";
import { Employee, Statistics } from "../../types/Employee";
import EmployeeStatistics from "./EmployeeStatistics";
import EmployeesFilter from "./EmployeesFilter";
import { ModalProperties } from "../../types/ModalProperties";
import { findUserStatistics, getAggregatedStatistics } from "utils/helper";
import { useMsal } from "@azure/msal-react";
import IconLoading from "../icons/IconLoading";
import ErrorMessage from "../base/ErrorMessage";
import { useLanguage } from "../../contexts/LanguageContext";

interface EmployeesProps {
  view: "USER" | "HR" | "BOARD";
  employees: Employee[];
  statistics: Statistics[];
  filter: Filter;
  setFilter: Dispatch<SetStateAction<Filter>>;
  setModalProperties: (modalProperties: ModalProperties) => void;
  refetchFn: () => void;
  overtakeAvailable: boolean;
  employeeStatisticsStatus?: string;
}

const Employees = ({
  view,
  employees,
  statistics,
  filter,
  setFilter,
  setModalProperties,
  refetchFn,
  overtakeAvailable,
  employeeStatisticsStatus,
}: EmployeesProps) => {
  const ownUpn = useMsal().instance.getActiveAccount()?.username.toLowerCase();

  const { messages } = useLanguage();

  const [, setExpandedUpns] = useState(
    sessionStorage.getItem("expandedUpns") &&
      sessionStorage.getItem("expandedUpns")?.length
      ? (sessionStorage.getItem("expandedUpns")?.split(",") as string[])
      : ([] as string[]),
  );

  const getTopStatistic = () => {
    if (employeeStatisticsStatus === "loading") {
      return (
        <div className="top-statistic-loading">
          <IconLoading color="black" />
        </div>
      );
    } else {
      return view === "USER" ? (
        employees.length > 0 && ownUpn ? (
          <div className="top-statistic">
            <EmployeeStatistics
              employeeStatistics={findUserStatistics(ownUpn, statistics)!}
            />
          </div>
        ) : (
          <div className="top-statistic">
            <EmployeeStatistics employeeStatistics={undefined} />
          </div>
        )
      ) : (
        <div className="top-statistic">
          <EmployeeStatistics
            employeeStatistics={getAggregatedStatistics(statistics, employees)}
          />
        </div>
      );
    }
  };

  return (
    <>
      {getTopStatistic()}
      <EmployeesFilter
        filter={filter}
        setFilter={setFilter}
        employees={employees}
        setModalProperties={setModalProperties}
        overtakeAllowed={overtakeAvailable}
        refetchFn={refetchFn}
        view={view}
      />
      {employees.length > 0 ? (
        employees.map((employee) => (
          <div
            className="flex-column-content-center"
            key={`employee-overview-card-${employee.upn}`}
          >
            <EmployeeCard
              key={employee.upn}
              employee={employee}
              statistics={statistics}
              employeeStatistics={findUserStatistics(employee.upn, statistics)}
              employeeStatisticsStatus={employeeStatisticsStatus}
              setModalProperties={setModalProperties}
              refetchFn={refetchFn}
              overtakeAvailable={overtakeAvailable}
              setExpandedUpns={setExpandedUpns}
            />
          </div>
        ))
      ) : (
        <div className="mt-32">
          <ErrorMessage message={messages.employeeError} />
        </div>
      )}
    </>
  );
};

export default Employees;
