enum Seniority {
  NOT_SET = "NOT_SET",
  ASSOCIATE = "ASSOCIATE",
  CONSULTANT = "CONSULTANT",
  SENIOR = "SENIOR",
  AM = "AM",
  KAM = "KAM",
  SENIOR_KAM = "SENIOR_KAM",
  LEAD = "LEAD",
  SENIOR_LEAD = "SENIOR_LEAD",
  LL_1 = "LL_1",
  LL_2 = "LL_2",
  LL_3 = "LL_3",
  LL_4 = "LL_4",
  LL_5 = "LL_5",
  LL_6 = "LL_6",
  OTHER = "OTHER",
}

export default Seniority;
