import ApprovalStatus from "types/ApprovalStatus";
import ActionButton from "components/base/ActionButton";
import IconCheck from "components/icons/IconCheck";
import IconEmailCheck from "components/icons/IconEmailCheck";
import IconTimeSand from "components/icons/IconTimeSand";
import { postApproval, postOvertake, postRelease } from "../../api";
import useRequestHeader from "../../hooks/useRequestHeaders";
import { useContext } from "react";
import NotificationContext from "../../contexts/NotificationContext";
import IconIncrease from "../icons/IconIncrease";
import { ModalProperties } from "../../types/ModalProperties";
import { useLanguage } from "../../contexts/LanguageContext";

interface ProposalActionProps {
  className?: string;
  upn: string;
  name: string;
  status: ApprovalStatus;
  availableActions: string;
  setModalProperties?: (modalProperties: ModalProperties) => void;
  refetchFn: () => void;
  notEditable: boolean;
  validate?: () => string;
  updateDataFn?: () => void;
}

const icons = {
  [ApprovalStatus.IN_PROGRESS]: <IconEmailCheck />,
  [ApprovalStatus.READY_TO_APPROVE]: <IconTimeSand />,
  [ApprovalStatus.RELEASED]: <IconCheck />,
  [ApprovalStatus.APPROVED]: <IconEmailCheck />,
  OVERTAKE: <IconIncrease />,
};

const ProposalAction = ({
  className,
  upn,
  name,
  status,
  availableActions,
  setModalProperties,
  refetchFn,
  notEditable,
  validate,
  updateDataFn,
}: ProposalActionProps) => {
  const { messages } = useLanguage();

  const headers = useRequestHeader();
  const setNotification = useContext(NotificationContext)[1];

  const hasAvailableActions = availableActions !== "NONE";

  const handleAction = async () => {
    if (availableActions === "APPROVE") {
      if (updateDataFn) {
        updateDataFn();
      }
      await postApproval(upn, headers)
        .then(() => {
          refetchFn();
          setNotification(messages.approveSalaryProposal.succeed);
        })
        .catch(() => setNotification(messages.approveSalaryProposal.failed));
    } else if (availableActions === "OVERTAKE") {
      await postOvertake(upn, headers)
        .then(() => {
          refetchFn();
          setNotification(messages.approveSalaryProposal.succeed);
        })
        .catch(() => setNotification(messages.approveSalaryProposal.failed));
    } else if (availableActions === "RELEASE") {
      await postRelease(upn, headers)
        .then(() => {
          refetchFn();
          setNotification(messages.approveSalaryProposal.succeed);
        })
        .catch(() => setNotification(messages.approveSalaryProposal.failed));
    }
    if (setModalProperties) {
      setModalProperties({
        visible: false,
        header: "",
        content: "",
        showCloseButton: false,
        confirmButtonText: "",
        confirmButtonAction: undefined,
      });
    }
  };

  const onClick = async () => {
    if (setModalProperties) {
      if (validate && availableActions === "APPROVE") {
        const validationResult = validate();
        if (validationResult !== "") {
          setModalProperties({
            visible: true,
            header: messages.information.validationFailed,
            content: (
              <div>
                <span>{validationResult}</span>
              </div>
            ),
            showCloseButton: true,
            confirmButtonText: "OK",
            confirmButtonAction: undefined,
          });
          return;
        }
      }

      setModalProperties({
        visible: true,
        header: messages.actionHeader[availableActions],
        content: (
          <div>
            <span>{messages.actionText[availableActions]}</span>
            <br />
            <br />
            <ul>
              <li key={"employee-" + availableActions + upn}>{name}</li>
            </ul>
          </div>
        ),
        showCloseButton: true,
        confirmButtonText: "OK",
        confirmButtonAction: handleAction,
      });
    }
  };

  const getLabelForAction = () => {
    if (availableActions === "OVERTAKE") {
      return messages.proposalAction.OVERTAKE;
    } else if (availableActions === "APPROVE") {
      return messages.proposalAction.IN_PROGRESS;
    } else if (availableActions === "RELEASE") {
      return messages.proposalAction.RELEASE;
    } else if (notEditable) {
      return messages.proposalAction.FORWARDED;
    } else {
      return messages.proposalAction[status];
    }
  };

  return (
    <ActionButton
      className={className}
      disabled={!hasAvailableActions}
      onClick={onClick}
      warning={availableActions === "OVERTAKE"}
      action={availableActions === "APPROVE" || availableActions === "RELEASE"}
      label={getLabelForAction()}
    >
      {availableActions === "OVERTAKE" && status === ApprovalStatus.IN_PROGRESS
        ? icons.OVERTAKE
        : icons[status]}
    </ActionButton>
  );
};

export default ProposalAction;
