import React, { useContext, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  EmployeeEditable,
  IEmployeeProfile,
  SalaryHistory,
} from "types/Employee";
import Card from "components/base/Card";
import ActionButton from "components/base/ActionButton";
import ProposalAction from "components/base/ProposalAction";
import EmployeeProfile from "components/base/EmployeeProfile";
import IconSave from "components/icons/IconSave";
import IconArrowLeft from "components/icons/IconArrowLeft";

import EmployeeSalaryProposal from "./EmployeeSalaryProposal";
import EmployeeSalaryExpectation from "./EmployeeSalaryExpectation";
import EmployeeSalaryHistory from "./EmployeeSalaryHistory";
import EmployeeSalaryComment from "./EmployeeSalaryComment";
import ApprovalStatus from "../../types/ApprovalStatus";
import { getEmployeeDetailByUPN, updateEmployeeDetailByUPN } from "../../api";
import NotificationContext from "../../contexts/NotificationContext";
import useRequestHeader from "../../hooks/useRequestHeaders";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import IconLoading from "../icons/IconLoading";
import ErrorMessage from "../base/ErrorMessage";
import Modal from "../base/Modal";
import { ModalProperties } from "../../types/ModalProperties";
import RoleContext from "../../contexts/RoleContext";
import { useLanguage } from "../../contexts/LanguageContext";
import IconReset from "../icons/IconReset";
import Button from "../base/Button";

const EmployeeSalary = () => {
  const { messages } = useLanguage();
  const role = useContext(RoleContext)[0];
  const headers = useRequestHeader();
  const { employeeUPN } = useParams();
  const queryClient = useQueryClient();

  const [editableSalaryHistory, setEditableSalaryHistory] =
    useState<EmployeeEditable>({} as EmployeeEditable);
  const [employeeProfile, setEmployeeProfile] = useState<IEmployeeProfile>(
    {} as IEmployeeProfile,
  );

  const [modalProperties, setModalProperties] = useState<ModalProperties>({
    visible: false,
    header: "",
    content: "",
    showCloseButton: true,
    confirmButtonText: "",
    confirmButtonAction: undefined,
  });

  const invalidateCache = () => {
    void queryClient.invalidateQueries({ queryKey: ["employeeDetail"] });
  };

  const { status: queryStatus, data: employeeDetailData } = useQuery(
    ["employeeDetail"],
    () =>
      getEmployeeDetailByUPN(String(employeeUPN), headers).then((response) => {
        setEditableSalaryHistory({
          effectiveFrom: response.currentSalaryHistory.effectiveFrom,
          fixum: response.currentSalaryHistory.fixum,
          variable: response.currentSalaryHistory.variable,
          singlePayment: response.currentSalaryHistory.singlePayment,
          hours: response.currentSalaryHistory.hours,
          seniority: response.currentSalaryHistory.seniority,
          degree: response.currentSalaryHistory.degree,
          domain: response.currentSalaryHistory.domain,
          jobTitle: response.currentSalaryHistory.jobTitle,
          teamlead: response.currentSalaryHistory.teamlead,
          currency: response.currentSalaryHistory.currency,
          reason: response.currentSalaryHistory.reason,
          normedFixum:
            (response.currentSalaryHistory.fixum /
              response.currentSalaryHistory.hours) *
            40,
          normedVariable:
            (response.currentSalaryHistory.variable /
              response.currentSalaryHistory.hours) *
            40,
          companyCar: response.currentSalaryHistory.companyCar,
        });
        setEmployeeProfile({
          firstName: response.firstName,
          lastName: response.lastName,
          availableActions: response.availableActions,
          approvalStatus: response.currentStatusOfSalaryHistoryRecord,
          hours: (response.lastSalaryHistory ?? response.currentSalaryHistory)
            .hours,
          jobTitle: (
            response.lastSalaryHistory ?? response.currentSalaryHistory
          ).jobTitle,
          seniority: (
            response.lastSalaryHistory ?? response.currentSalaryHistory
          ).seniority,
          newPromotion: response.newPromotion,
          location: response.location,
          costQuota: response.costQuota,
          projectQuota: response.projectQuota,
          upn: response.upn,
          teamLead: (
            response.lastSalaryHistory ?? response.currentSalaryHistory
          ).teamlead,
          currentSalaryHistory: response.currentSalaryHistory,
          lastSalaryHistory: response.lastSalaryHistory,
        });

        return response;
      }),
  );

  const setNotification = useContext(NotificationContext)[1];

  const handleResetClick = async () => {
    if (setModalProperties) {
      setModalProperties({
        visible: true,
        header: messages.actionHeader.RESET,
        content: messages.actionText.RESET,
        showCloseButton: true,
        confirmButtonText: "OK",
        confirmButtonAction: employeeProfile.lastSalaryHistory
          ? () => updateEmployeeDetail(employeeProfile.lastSalaryHistory)
          : () => undefined,
      });
    }
  };

  const handleSaveClick = async () => {
    if (setModalProperties) {
      setModalProperties({
        visible: true,
        header: messages.actionHeader.SAVE,
        content: messages.actionText.SAVE,
        showCloseButton: true,
        confirmButtonText: "OK",
        confirmButtonAction: updateEmployeeDetail,
      });
    }
  };

  const updateEmployeeDetail = (givenHistory?: SalaryHistory) => {
    const submittableEditableSalaryHistory = {
      ...(givenHistory ?? editableSalaryHistory),
    } as any;
    if (givenHistory) {
      submittableEditableSalaryHistory.hours = editableSalaryHistory.hours;
      submittableEditableSalaryHistory.fixum =
        (givenHistory.fixum / givenHistory.hours) * editableSalaryHistory.hours;
      submittableEditableSalaryHistory.variable =
        (givenHistory.variable / givenHistory.hours) *
        editableSalaryHistory.hours;
    }
    delete submittableEditableSalaryHistory.normedFixum;
    delete submittableEditableSalaryHistory.normedVariable;

    if (employeeUPN) {
      return updateEmployeeDetailByUPN(
        employeeUPN,
        headers,
        submittableEditableSalaryHistory,
      )
        .then(() => {
          invalidateCache();
          setNotification(messages.approveSalaryProposal.succeed);
        })
        .catch((error) => console.log(error));
    }
  };

  const isOvertakeAllowed = () =>
    !(role === "BOARD" && employeeProfile.availableActions === "OVERTAKE");

  const validateEmployeeData = () => {
    if (!editableSalaryHistory.reason) {
      return messages.information.reasonRequired;
    }
    return "";
  };

  return (
    <>
      {queryStatus === "error" && (
        <div className="flex-col-items-content-center mt-32">
          <ErrorMessage
            message={messages.fetchEmployeeFailed.content}
            additionalBodyElement={
              <Button
                type="secondary"
                caption={messages.information.retry}
                clickFn={() => invalidateCache()}
              />
            }
          />
        </div>
      )}
      {queryStatus === "success" && employeeDetailData !== undefined && (
        <section className="employee-salary">
          <Link className="employee-salary-header" to="/">
            <IconArrowLeft />
            <span>{messages.back}</span>
          </Link>
          <div className="cards">
            <Card className="employee-salary-profile">
              <EmployeeProfile
                employeeProfile={employeeProfile}
                overtakeAllowed={isOvertakeAllowed()}
                showComparison={true}
                ignoreMissingProps={true}
                editableSalaryHistory={editableSalaryHistory}
              />
              {employeeProfile.availableActions !== "NONE" && (
                <div className="employee-salary-cta">
                  {employeeProfile.availableActions === "APPROVE" && (
                    <>
                      {employeeProfile.lastSalaryHistory !== undefined && (
                        <ActionButton
                          label={messages.reset}
                          onClick={handleResetClick}
                        >
                          <IconReset />
                        </ActionButton>
                      )}
                      <ActionButton
                        label={messages.save}
                        onClick={handleSaveClick}
                      >
                        <IconSave />
                      </ActionButton>
                    </>
                  )}
                  <ProposalAction
                    upn={employeeDetailData.upn}
                    name={
                      employeeDetailData.firstName +
                      " " +
                      employeeDetailData.lastName
                    }
                    status={
                      employeeDetailData.currentSalaryHistory
                        .status as ApprovalStatus
                    }
                    availableActions={employeeDetailData.availableActions}
                    setModalProperties={setModalProperties}
                    refetchFn={invalidateCache}
                    notEditable={
                      !!(
                        employeeProfile.availableActions !== "NONE" &&
                        employeeDetailData.currentSalaryHistory.status !==
                          "RELEASED" &&
                        employeeDetailData.currentApproverUpn
                      )
                    }
                    validate={validateEmployeeData}
                    updateDataFn={updateEmployeeDetail}
                  />
                </div>
              )}
            </Card>
            <EmployeeSalaryProposal
              employee={employeeDetailData}
              editableSalaryHistory={editableSalaryHistory}
              setEditableSalaryHistory={setEditableSalaryHistory}
            />
            <EmployeeSalaryExpectation
              employee={employeeDetailData}
              editableSalaryHistory={editableSalaryHistory}
              setEditableSalaryHistory={setEditableSalaryHistory}
            />
            <EmployeeSalaryHistory
              employee={employeeDetailData}
              setModalProperties={setModalProperties}
            />
            <EmployeeSalaryComment
              disabled={employeeProfile.availableActions !== "APPROVE"}
              editableSalaryHistory={editableSalaryHistory}
              setEditableSalaryHistory={setEditableSalaryHistory}
            />
          </div>
        </section>
      )}
      {queryStatus === "loading" && (
        <div className="flex-col-items-content-center mt-32">
          <IconLoading />
        </div>
      )}
      <Modal
        visible={modalProperties.visible}
        setVisible={(visible: boolean) =>
          setModalProperties((modalProperties) => ({
            ...modalProperties,
            visible,
          }))
        }
        header={modalProperties.header}
        content={modalProperties.content}
        confirmButtonText={modalProperties.confirmButtonText}
        confirmButtonAction={modalProperties.confirmButtonAction}
        showCloseButton={modalProperties.showCloseButton}
      />
    </>
  );
};

export default EmployeeSalary;
