import Seniority from "types/Seniority";
import ApprovalStatus from "types/ApprovalStatus";
import { Domain } from "../types/Domain";

export const seniorityMessages: { [key in Seniority]: any } = {
  // ALL
  [Seniority.NOT_SET]: {
    label: "Unknown",
    domains: [Domain.CENTRAL_FUNCTIONS, Domain.DELIVERY, Domain.SALES],
  },
  // EDGE-CASE
  [Seniority.OTHER]: {
    label: "OTHER",
    domains: [],
  },
  // DELIVERY
  [Seniority.ASSOCIATE]: { label: "Associate", domains: [Domain.DELIVERY] },
  [Seniority.CONSULTANT]: { label: "Consultant", domains: [Domain.DELIVERY] },
  [Seniority.SENIOR]: { label: "Senior", domains: [Domain.DELIVERY] },
  // SALES
  [Seniority.AM]: { label: "Account Manager", domains: [Domain.SALES] },
  [Seniority.KAM]: { label: "Key Account Manager", domains: [Domain.SALES] },
  [Seniority.SENIOR_KAM]: {
    label: "Senior Key Account Manager",
    domains: [Domain.SALES],
  },
  // DELIVERY + SALES
  [Seniority.LEAD]: {
    label: "Lead",
    domains: [Domain.DELIVERY, Domain.SALES],
  },
  [Seniority.SENIOR_LEAD]: {
    label: "Senior Lead",
    domains: [Domain.DELIVERY, Domain.SALES],
  },
  // CENTRAL_FUNCTIONS
  [Seniority.LL_1]: {
    label: "Lead Level 1",
    domains: [Domain.CENTRAL_FUNCTIONS],
  },
  [Seniority.LL_2]: {
    label: "Lead Level 2",
    domains: [Domain.CENTRAL_FUNCTIONS],
  },
  [Seniority.LL_3]: {
    label: "Lead Level 3",
    domains: [Domain.CENTRAL_FUNCTIONS],
  },
  [Seniority.LL_4]: {
    label: "Lead Level 4",
    domains: [Domain.CENTRAL_FUNCTIONS],
  },
  [Seniority.LL_5]: {
    label: "Lead Level 5",
    domains: [Domain.CENTRAL_FUNCTIONS],
  },
  [Seniority.LL_6]: {
    label: "Lead Level 6",
    domains: [Domain.CENTRAL_FUNCTIONS],
  },
};

export const messages_de = {
  approvalStatus: {
    [ApprovalStatus.IN_PROGRESS]: "In Arbeit",
    [ApprovalStatus.READY_TO_APPROVE]: "Vorbereitet",
    [ApprovalStatus.RELEASED]: "Freigegeben",
    [ApprovalStatus.APPROVED]: "Eingereicht",
  },
  proposalAction: {
    [ApprovalStatus.IN_PROGRESS]: "Genehmigen",
    [ApprovalStatus.READY_TO_APPROVE]: "Genehmigen",
    [ApprovalStatus.RELEASED]: "Freigegeben",
    [ApprovalStatus.APPROVED]: "Eingereicht",
    OVERTAKE: "Übernehmen",
    FORWARDED: "Weitergeleitet",
    RELEASE: "Freigeben",
  },
  weekLabel: " H / Woche",
  targetSalaryLabel: "Neues geplantes Gehalt",
  commentLabel: "Kommentar",
  approvalRequiredLabel: "Deine Freigabe ist notwendig",
  approvalNextApproversLabel: "Aktueller Bearbeiter: ",
  usedYear: "2024",
  lastYear: "2023",
  plannedActualCost: "Geplante Kosten (tatsächlich)",
  plannedNormedCost: "Geplante Kosten (normiert)",
  plannedActualSalary: "Geplantes Gehalt (tatsächlich)",
  plannedNormedSalary: "Geplantes Gehalt (normiert)",
  absoluteIncrease: "Absolute Erhöhung",
  percentageIncrease: "Prozentuale Erhöhung",
  plannedSinglePayment: "geplanter Bonus",
  plannedVariablePortion: "geplanter variabler Anteil",
  singlePaymentDelivery: "Auszahlungsdatum",
  salaryExpectation: "Gehaltserwartung",
  salaryExpectationFixum: "Gehaltserwartung (fix)",
  salaryExpectationVariable: "Gehaltserwartung (variabel)",
  leadLevel: "Senioritätsebene",
  responsibility: "Teamverantwortung",
  position: "gepl. Funktionsbezeichnung",
  back: "Zurück",
  save: "Entwurf speichern",
  reset: "Zurücksetzen",
  fixum: "Fixum",
  variable: "Variabel",
  variablePortion: "Variabler Anteil",
  target: "Ziel",
  companyCar: "Dienstwagen",
  companyCarReverted: "Wagen Dienst",
  salaryHistory: "Gehaltshistorie (normierte Gehälter)",
  comment: "Begründung",
  ok: "OK",
  welcome: "Willkommen",
  notLogin: "Du bist nicht eingeloggt.",
  login: "Login",
  selectAllEmployees: "Alle Mitarbeiter auswählen",
  selectedEmployees: "Mitarbeiter ausgewählt",
  saveSalaryProposal: {
    succeed: {
      header: "Erfolgt!",
      content: "Änderungen wurden gespeichert!",
    },
    failed: {
      header: "Fehler!",
      content: "Fehler beim Anpassen der Daten. Bitte HR kontaktieren!",
    },
  },
  skipSubordinates: {
    succeed: {
      header: "Erfolgt!",
      content: "Änderungen wurden gespeichert!",
    },
    failed: {
      header: "Fehler!",
      content: "Fehler beim Anpassen der Daten. Bitte HR kontaktieren!",
    },
  },
  approveSalaryProposal: {
    succeed: {
      header: "Erfolgt!",
      content: "Änderungen wurden gespeichert!",
    },
    failed: {
      header: "Fehler!",
      content: "Fehler beim Anpassen der Daten. Bitte HR kontaktieren!",
    },
  },
  fetchEmployeesFailed: {
    header: "Oops!",
    content: "Fehler beim Empfangen der Mitarbeiter. Bitte HR kontaktieren!",
  },
  fetchEmployeeFailed: {
    header: "Oops!",
    content:
      "Fehler beim Empfangen der Mitarbeiterdaten. Bitte erneut versuchen oder HR kontaktieren!",
  },
  actionHeader: {
    NONE: "Fehler",
    APPROVE: "Genehmigen",
    OVERTAKE: "Übernehmen",
    RELEASE: "Freigeben",
    SAVE: "Entwurf speichern",
    RESET: "Mitarbeiter-Daten zurücksetzen",
    SHOW_EMPLOYEES: "Mitarbeiter anzeigen",
    HIDE_EMPLOYEES: "Mitarbeiter verstecken",
  },
  actionText: {
    NONE: "Dies sollte nicht passieren.",
    APPROVE: "Bist du sicher, dass du diese(n) Nutzer genehmigen möchtest?",
    OVERTAKE: "Bist du sicher, dass du diese(n) Nutzer übernehmen möchtest?",
    RELEASE: "Bist du sicher, dass du diese(n) Nutzer freigeben möchtest?",
    SAVE: "Bist du sicher, dass du die Änderungen speichern willst?",
    RESET:
      "Bist du sicher, dass du diesen Nutzer zurück auf den letzten genehmigten Stand setzen willst?",
  },
  degree: "Entwicklungsgrad",
  genderCaption: "Geschlecht",
  gender: {
    MALE: "männlich",
    FEMALE: "weiblich",
  },
  locationCaption: "Standort",
  location: {
    BERLIN: "Berlin",
    BRATISLAVA: "Bratislava",
    BRAUNSCHWEIG: "Braunschweig",
    FRANKFURT: "Frankfurt",
    HAMBURG: "Hamburg",
    KARLSRUHE: "Karlsruhe",
    LEIPZIG: "Leipzig",
    MANNHEIM: "Mannheim",
    MUENCHEN: "München",
    NEUSS: "Neuss",
    NUERNBERG: "Nürnberg",
    STUTTGART: "Stuttgart",
    ZUERICH: "Zürich",
  },
  seniorityLevelCaption: "Seniorität",
  seniorityLevel: {
    ASSOCIATE: "Associate",
    CONSULTANT: "Consultant",
    SENIOR: "Senior",
    LEAD: "Lead",
    SENIOR_LEAD: "Senior Lead",
    AM: "Assistant Manager",
    KAM: "Key Account Manager",
    SENIOR_KAM: "Senior Key Account Manager",
    LL_1: "Lead Level 1",
    LL_2: "Lead Level 2",
    LL_3: "Lead Level 3",
    LL_4: "Lead Level 4",
    LL_5: "Lead Level 5",
    LL_6: "Lead Level 6",
    OTHER: "OTHER",
  },
  statusCaption: "Status",
  status: {
    APPROVED: "Eingereicht",
    IN_PROGRESS: "In Arbeit",
    RELEASED: "Freigegeben",
    READY_TO_APPROVE: "Vorbereitet",
  },
  sum: "Summe",
  sumNormedSalary: "Summe normiertes Jahresgehalt",
  sumBonus: "Summe Bonus",
  sumBonusTooltip: "Summe der Boni",
  absolute: "Absolut",
  absoluteSumTooltip: "Steigerung absolutes normiertes Jahresgehalt",
  percent: "Prozent",
  percentSumTooltip: "Steigerung normiertes Jahresgehalt in Prozent",
  plannedBudgetSalaryTooltip: "Geplantes Budget Gehalt",
  budgetSalary: "Budget Gehalt",
  plannedBudgetBonusTooltip: "Geplantes Budget Bonus",
  budgetBonus: "Budget Bonus",
  amountOfEmployeesTooltip: "Anzahl Mitarbeiter",
  employees: "Mitarbeiter",
  amountOfPromotionsTooltip: "Anzahl Beförderungen",
  promotions: "Beförderungen",
  amountOfNewLeadsTooltip: "Anzahl neue Führungskräfte",
  newLeads: "Neue Führungskräfte",
  raiseMoreThan: "Erhöhung mehr als",
  decreaseLessThan: "Minderung kleiner als",
  promotion: "Beförderung",
  employeeError:
    "Keine Mitarbeiter gefunden. Bitte überprüfe deine Filtereinstellungen oder kontaktiere HR.",
  projectQuota: "Projektquote",
  costRelief: "Kostenentlastung",
  avgNewSalary: "Durchschnittsgehalt",
  avgNewSalaryTooltip: "Durchschnittl. neues Gehalt (mit Abweichung)",
  information: {
    reasonRequired: "Eine Begründung ist immer notwendig",
    reasonIssue1: "Die prozentuale Erhöhung ist größer oder gleich 5%.",
    reasonIssue2: "Der Mitarbeitende erhält eine Beförderung.",
    reasonIssue3: "Der Mitarbeitende hat in Zukunft Teamverantwortung.",
    noResponsibility: "Keine Teamverantwortung",
    statisticsNotFound: "Statistik nicht gefunden.",
    yes: "Ja",
    no: "Nein",
    weeklyHours: "Wochenstunden",
    singlePayment: "Einmalzahlung",
    domain: "Domäne",
    positionTitle: "Funktionsbezeichnung",
    approvedAt: "genehmigt am",
    effectiveFrom: "effektiv ab",
    history: "Historie",
    approvalDate: "Genehmigungsdatum",
    validationFailed: "Validierung fehlgeschlagen",
    retry: "Erneut versuchen",
    connectionInfo:
      "Lade Basisinformationen... falls dies zu lange dauern sollte, bist du vielleicht nicht im Firmen-VPN - ansonsten kontaktiere HR!",
  },
  degreeLevels: {
    BEGONNEN: "Begonnen",
    GEFESTIGT: "Gefestigt",
    AUF_DEM_SPRUNG: "Auf dem Sprung",
  },
  language: "Sprache",
};

export const messages_en = {
  approvalStatus: {
    [ApprovalStatus.IN_PROGRESS]: "In progress",
    [ApprovalStatus.READY_TO_APPROVE]: "Prepared",
    [ApprovalStatus.RELEASED]: "Released",
    [ApprovalStatus.APPROVED]: "Submitted",
  },
  proposalAction: {
    [ApprovalStatus.IN_PROGRESS]: "Approve",
    [ApprovalStatus.READY_TO_APPROVE]: "Approve",
    [ApprovalStatus.RELEASED]: "Released",
    [ApprovalStatus.APPROVED]: "Submitted",
    OVERTAKE: "Take over",
    FORWARDED: "Forwarded",
    RELEASE: "Release",
  },
  weekLabel: " H / Week",
  targetSalaryLabel: "New planned salary",
  commentLabel: "Comment",
  approvalRequiredLabel: "Your approval is necessary",
  approvalNextApproversLabel: "Current editor: ",
  plannedActualCost: "Planned costs (actual)",
  plannedNormedCost: "Planned costs (normed)",
  plannedActualSalary: "Planned salary (actual)",
  plannedNormedSalary: "Planned salary (normed)",
  absoluteIncrease: "Absolute increase",
  percentageIncrease: "Percentage increase",
  plannedSinglePayment: "Planned single payment",
  plannedVariablePortion: "Planned variable portion",
  singlePaymentDelivery: "Payment date",
  salaryExpectation: "Salary expectation",
  salaryExpectationFixum: "Salary expectation (fixum)",
  salaryExpectationVariable: "Salary expectation (variable)",
  leadLevel: "Lead level",
  responsibility: "Team responsibility",
  position: "Planned job title",
  back: "Back",
  save: "Save draft",
  fixum: "Fixum",
  variable: "Variable",
  variablePortion: "Variable portion",
  target: "Target",
  companyCar: "Company car",
  companyCarReverted: "car Company",
  salaryHistory: "Salary history (normed)",
  comment: "Justification",
  reset: "Reset",
  ok: "OK",
  welcome: "Welcome",
  notLogin: "You are not logged in.",
  login: "Login",
  selectAllEmployees: "Select all employees",
  selectedEmployees: "Employees selected",
  saveSalaryProposal: {
    succeed: {
      header: "Done!",
      content: "Changes have been saved!",
    },
    failed: {
      header: "Error!",
      content: "Error while adjusting the data. Please contact HR!",
    },
  },
  skipSubordinates: {
    succeed: {
      header: "Done!",
      content: "Changes have been saved!",
    },
    failed: {
      header: "Error!",
      content: "Error while adjusting the data. Please contact HR!",
    },
  },
  approveSalaryProposal: {
    succeed: {
      header: "Done!",
      content: "Changes have been saved!",
    },
    failed: {
      header: "Error!",
      content: "Error while adjusting the data. Please contact HR!",
    },
  },
  fetchEmployeesFailed: {
    header: "Oops!",
    content: "Error receiving employees. Please contact HR!",
  },
  fetchEmployeeFailed: {
    header: "Oops!",
    content: "Error receiving employee data. Please try again or contact HR!",
  },
  actionHeader: {
    NONE: "Error",
    APPROVE: "Approve",
    OVERTAKE: "Take over",
    RELEASE: "Release",
    SAVE: "Save draft",
    RESET: "Reset employee data",
    SHOW_EMPLOYEES: "Show employees",
    HIDE_EMPLOYEES: "Hide employees",
  },
  actionText: {
    NONE: "This should not happen.",
    APPROVE: "Are you sure you want to approve this user?",
    OVERTAKE: "Are you sure you want to take over this user?",
    RELEASE: "Are you sure you want to release this user?",
    SAVE: "Are you sure you want to save the changes?",
    RESET:
      "Are you sure you want to reset this user to the previous approved state?",
  },
  degree: "Development deg.",
  genderCaption: "Gender",
  gender: {
    MALE: "Male",
    FEMALE: "Female",
  },
  locationCaption: "Location",
  location: {
    BERLIN: "Berlin",
    BRATISLAVA: "Bratislava",
    BRAUNSCHWEIG: "Brunswick",
    FRANKFURT: "Frankfurt",
    HAMBURG: "Hamburg",
    KARLSRUHE: "Karlsruhe",
    LEIPZIG: "Leipzig",
    MANNHEIM: "Mannheim",
    MUENCHEN: "Munich",
    NEUSS: "Neuss",
    NUERNBERG: "Nuremberg",
    STUTTGART: "Stuttgart",
    ZUERICH: "Zurich",
  },
  seniorityLevelCaption: "Seniority",
  seniorityLevel: {
    ASSOCIATE: "Associate",
    CONSULTANT: "Consultant",
    SENIOR: "Senior",
    LEAD: "Lead",
    SENIOR_LEAD: "Senior Lead",
    AM: "Assistant Manager",
    KAM: "Key Account Manager",
    SENIOR_KAM: "Senior Key Account Manager",
    LL_1: "Lead Level 1",
    LL_2: "Lead Level 2",
    LL_3: "Lead Level 3",
    LL_4: "Lead Level 4",
    LL_5: "Lead Level 5",
    LL_6: "Lead Level 6",
    OTHER: "OTHER",
  },
  statusCaption: "Status",
  status: {
    APPROVED: "Submitted",
    IN_PROGRESS: "In Progress",
    RELEASED: "Released",
    READY_TO_APPROVE: "Prepared",
  },
  sum: "Sum",
  sumNormedSalary: "Sum normed salaries",
  sumBonus: "Sum bonus",
  sumBonusTooltip: "Sum of bonus",
  absolute: "Absolute",
  absoluteSumTooltip: "Absolute normed salary raise",
  percent: "Percent",
  percentSumTooltip: "Percentage normed salary raise",
  plannedBudgetSalaryTooltip: "Planned budget salary",
  budgetSalary: "Budget salary",
  plannedBudgetBonusTooltip: "Planned budget bonus",
  budgetBonus: "Budget bonus",
  amountOfEmployeesTooltip: "Amount of employees",
  employees: "Employees",
  amountOfPromotionsTooltip: "Amount of promotions",
  promotions: "Promotions",
  amountOfNewLeadsTooltip: "Amount of new leads",
  newLeads: "New leads",
  raiseMoreThan: "Raise higher than",
  decreaseLessThan: "Decrease less than",
  promotion: "Promotion",
  employeeError:
    "No employees found. Please check your filter selection or contact HR.",
  projectQuota: "Project quota",
  costRelief: "Cost relief",
  avgNewSalary: "Avg. salary",
  avgNewSalaryTooltip: "Avg. new salary (with deviation)",
  information: {
    reasonRequired: "A justification is always required",
    reasonIssue1: "The percentage increase is greater than or equal to 5%.",
    reasonIssue2: "The employee receives a promotion.",
    reasonIssue3: "In future, the employee will have team responsibility.",
    noResponsibility: "No team responsibility",
    statisticsNotFound: "Statistics not found.",
    yes: "Yes",
    no: "No",
    weeklyHours: "Weekly hours",
    singlePayment: "Single payment",
    domain: "Domain",
    positionTitle: "Job title",
    approvedAt: "approved at",
    effectiveFrom: "effective from",
    history: "History",
    approvalDate: "Date of approval",
    validationFailed: "Validation failed",
    retry: "Try again",
    connectionInfo:
      "Loading basic initial data... if this takes too long, you might not be connected through company VPN - otherwise contact HR!",
  },
  degreeLevels: {
    BEGONNEN: "Started",
    GEFESTIGT: "Consolidated",
    AUF_DEM_SPRUNG: "On the leap",
  },
  language: "Language",
};
