import Card from "components/base/Card";
import { Employee, IEmployeeProfile, Statistics } from "types/Employee";

import {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import EmployeeProfile from "../base/EmployeeProfile";
import EmployeeTargetSalary from "./EmployeeTargetSalary";
import EmployeeComment from "./EmployeeComment";
import {
  checkIfUserHasTeamResponsibility,
  findEmployeesWithAction,
} from "./functions";
import EmployeeStatistics from "./EmployeeStatistics";
import { ModalProperties } from "../../types/ModalProperties";
import { findUserStatistics } from "../../utils/helper";
import IconLoading from "../icons/IconLoading";
import { useLanguage } from "../../contexts/LanguageContext";

interface EmployeeCardProps {
  employee: Employee;
  statistics: Statistics[];
  employeeStatistics?: Statistics;
  employeeStatisticsStatus?: string;
  setModalProperties?: (modalProperties: ModalProperties) => void;
  refetchFn: () => void;
  overtakeAvailable: boolean;
  setExpandedUpns?: Dispatch<SetStateAction<string[]>>;
}

const EmployeeCard = ({
  employee,
  statistics,
  employeeStatistics,
  employeeStatisticsStatus,
  setModalProperties,
  refetchFn,
  overtakeAvailable,
  setExpandedUpns,
}: EmployeeCardProps) => {
  const { messages } = useLanguage();
  const [showSubEmployees, setShowSubEmployees] = useState(
    sessionStorage.getItem("expandedUpns")?.includes(employee.upn) ?? false,
  );

  const elementRef = useRef<HTMLDivElement>(null);

  const employeeProfile = useMemo(
    () =>
      ({
        upn: employee.upn,
        firstName: employee.firstName,
        lastName: employee.lastName,
        availableActions: employee.availableActions,
        approvalStatus: employee.currentStatusOfSalaryHistoryRecord,
        hours: employee.currentSalaryHistory?.hours,
        jobTitle: employee.currentSalaryHistory?.jobTitle,
        seniority: employee.currentSalaryHistory?.seniority,
        newPromotion: employee.newPromotion,
        location: employee.location,
        projectQuota: employee.projectQuota ?? 0,
        costQuota: employee.costQuota,
        teamSize: employee.team.find((e) => !!e.birthDate || !!e.team.length)
          ? employee.team.length
          : 0,
        lastSalaryHistory: employee.lastSalaryHistory,
      }) as IEmployeeProfile,
    [employee],
  );

  const validateEmployeeData = () => {
    if (employee.currentSalaryHistory) {
      if (!employee.currentSalaryHistory.reason) {
        return messages.information.reasonRequired;
      }
    }
    return "";
  };

  const getEmployeeStatistic = () => {
    return (
      !!employeeProfile.teamSize &&
      checkIfUserHasTeamResponsibility(employee) &&
      (employeeStatistics ? (
        <div className="subordinate-statistic">
          <EmployeeStatistics employeeStatistics={employeeStatistics} />
        </div>
      ) : !employeeStatistics && employeeStatisticsStatus === "success" ? (
        <div className="subordinate-statistic-loading">
          {messages.information.statisticsNotFound}
        </div>
      ) : (
        <div className="subordinate-statistic-loading">
          <IconLoading color="white" />
        </div>
      ))
    );
  };

  const expandHandle = (upn: string, expanded: boolean) => {
    if (setExpandedUpns) {
      if (expanded) {
        setExpandedUpns((expUpns) => {
          let newExpandedUpns;
          if (!expUpns.includes(upn)) {
            newExpandedUpns = [...expUpns, upn];
          } else {
            newExpandedUpns = expUpns;
          }
          sessionStorage.setItem("expandedUpns", newExpandedUpns.toString());
          return newExpandedUpns;
        });
      } else {
        setExpandedUpns((expUpns) => {
          let newExpandedUpns = expUpns;
          const filterOutNestedUpns = (
            expUpns: string[],
            team: Employee[],
          ): string[] => {
            team.forEach((e) => {
              expUpns = expUpns.filter((f) => f !== e.upn);
              if (e.team.length > 0) {
                expUpns = filterOutNestedUpns(expUpns, e.team);
              }
            });
            return expUpns;
          };
          if (employee.team.length > 0) {
            newExpandedUpns = filterOutNestedUpns(
              newExpandedUpns,
              employee.team,
            );
          }
          newExpandedUpns = newExpandedUpns.filter((e) => e !== upn);
          sessionStorage.setItem("expandedUpns", newExpandedUpns.toString());
          return newExpandedUpns;
        });
      }
    }
  };

  const scrollVisitedUpnIntoView = () => {
    if (sessionStorage.getItem("lastVisitedUpn") === employee.upn) {
      if (elementRef.current) {
        elementRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "center",
        });
        sessionStorage.removeItem("lastVisitedUpn");
      }
    }
  };

  useEffect(() => {
    scrollVisitedUpnIntoView();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card className="employee-card">
      <div style={{ display: "flex" }} ref={elementRef}>
        <div className="width-stretch" style={{ minWidth: "33.3%" }}>
          <EmployeeProfile
            employeeProfile={employeeProfile}
            showApprovalStatus
            setShowSubEmployees={(show: boolean) => {
              expandHandle(employee.upn, show);
              setShowSubEmployees(show);
            }}
            showSubEmployees={showSubEmployees}
            allOvertakeEmployees={findEmployeesWithAction(employee, "OVERTAKE")}
            allApproveEmployees={findEmployeesWithAction(employee, "APPROVE")}
            setModalProperties={setModalProperties}
            empty={!(employee.birthDate && employeeProfile)}
            refetchFn={refetchFn}
            overtakeAllowed={overtakeAvailable}
          />
        </div>
        <div
          className="width-stretch"
          style={{
            minWidth: "33.3%",
          }}
        >
          {employee.birthDate && <EmployeeTargetSalary employee={employee} />}
        </div>
        <div className="width-stretch" style={{ minWidth: "33.3%" }}>
          {employee.birthDate && (
            <EmployeeComment
              employee={employee}
              setModalProperties={setModalProperties}
              refetchFn={refetchFn}
              overtakeAllowed={
                !(
                  !overtakeAvailable && employee.availableActions === "OVERTAKE"
                )
              }
              validate={validateEmployeeData}
            />
          )}
        </div>
      </div>
      {getEmployeeStatistic()}
      {employee.team.length > 0 && showSubEmployees && (
        <div
          style={{
            paddingLeft: "20px",
            paddingRight: "20px",
            borderLeft: "1px solid grey",
            borderRight: "1px solid grey",
            borderBottom: "1px solid grey",
            borderBottomLeftRadius: "10px",
            borderBottomRightRadius: "10px",
          }}
        >
          {employee.team.map((e) => (
            <div
              className="flex-column-content-center"
              key={`employee-overview-card-${e.upn}`}
            >
              <EmployeeCard
                key={e.upn}
                employee={e}
                statistics={statistics}
                employeeStatistics={findUserStatistics(e.upn, statistics)}
                setModalProperties={setModalProperties}
                refetchFn={refetchFn}
                overtakeAvailable={overtakeAvailable}
                setExpandedUpns={setExpandedUpns}
              />
            </div>
          ))}
        </div>
      )}
    </Card>
  );
};

export default EmployeeCard;
