import React from "react";

const IconLoading: React.FC<{ dimension?: number; color?: string }> = ({
  dimension,
  color,
}) => {
  return (
    <svg
      width={dimension ?? 24}
      height={dimension ?? 24}
      stroke="#000"
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g className="spinner_V8m1">
        <circle
          cx="12"
          cy="12"
          r="9.5"
          fill="none"
          strokeWidth="3"
          stroke={color}
        ></circle>
      </g>
    </svg>
  );
};

export default IconLoading;
