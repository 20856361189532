import { PropsWithChildren, useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import AccessTokenContext from "contexts/AccessTokenContext";

const AccessTokenContextProvider = ({ children }: PropsWithChildren) => {
  const { instance, accounts } = useMsal();
  const [accessToken, setAccessToken] = useState("");

  useEffect(() => {
    // Define Scope from env file and use the main account
    const loginRequest = {
      scopes: [String(process.env["REACT_APP_SCOPE"])],
      account: accounts[0],
    };

    /* This promise will return either a response (= user has been logged in and access token has been received and set)
       or null (= user has already been logged in or no active accounts). If no active accounts it will redirect to
       the Azure AD login screen. If user has been logged in before it will acquire the token silently, except if the
       user needs to reauthenticate. In this case he will be redirected to acquire the token.*/
    instance.handleRedirectPromise().then((response) => {
      if (response) {
        setAccessToken(response.accessToken);
        instance.setActiveAccount(response.account);
      } else {
        const accounts = instance.getAllAccounts();
        if (accounts.length === 0) {
          void instance.loginRedirect(loginRequest);
        } else {
          instance
            .acquireTokenSilent(loginRequest)
            .then((response) => {
              setAccessToken(response.accessToken);
              instance.setActiveAccount(response.account);
            })
            .catch((error) => {
              if (error instanceof InteractionRequiredAuthError) {
                void instance.acquireTokenRedirect(loginRequest);
              }
            });
        }
      }
    });
  }, [instance, accounts]);

  return (
    <AccessTokenContext.Provider value={accessToken}>
      {accessToken !== "" && children}
    </AccessTokenContext.Provider>
  );
};

export default AccessTokenContextProvider;
