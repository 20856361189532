import { Dispatch, SetStateAction } from "react";
import { Employee, SalaryHistory, Statistics } from "../../types/Employee";
import { Filter } from "../../types/Filter";

/*
 * Sets the ids to the multipleActionIds array which are checked by the user
 * @param isChecked
 * @param id
 */
export const addMultipleActionUPN = (
  setMultipleActionUPNs: Dispatch<SetStateAction<string[]>>,
  isChecked: boolean,
  upn: string,
) => {
  if (isChecked) {
    setMultipleActionUPNs((prevState) => {
      const newArray = [...prevState];
      newArray.push(upn);
      return newArray;
    });
  } else {
    setMultipleActionUPNs((prevState) =>
      prevState.filter((item) => item !== upn),
    );
  }
};

/**
 * Returns a boolean depending on if the user has responsibility or not
 * @param employee
 */
export const checkIfUserHasTeamResponsibility = (employee: Employee) => {
  return employee.team.length > 0;
};

/**
 * Returns a user's statistics
 * @param employee
 */
export const getUserStatistics = (employee: Employee) => {
  return {
    totalOldSalary: employee.totalOldSalary,
    totalNewSalary: employee.totalNewSalary,
    difference: employee.difference,
    differencePercent: employee.differencePercent,
    numOfEmployees: employee.numOfEmployees,
    numOfPromotions: employee.numOfPromotions,
    numOfNewManagers: employee.numOfNewManagers,
  } as Statistics;
};

/**
 * Returns an object to filter by to send to the backend
 */
export const filterRequestValues = (filter: Filter) => {
  const filterRequestObject = {} as any;
  if (filter.gender !== "") {
    filterRequestObject.gender = filter.gender;
  }
  if (filter.location !== "") {
    filterRequestObject.location = filter.location;
  }
  if (filter.seniority !== "") {
    filterRequestObject.seniority = filter.seniority;
  }
  if (filter.status !== "") {
    filterRequestObject.status = filter.status;
  }
  if (filter.responsibility) {
    filterRequestObject.responsibility = filter.responsibility;
  }
  if (filter.promotion) {
    filterRequestObject.promotion = filter.promotion;
  }
  if (filter.increase !== 0) {
    filterRequestObject.increase = filter.increase;
  }
  if (filter.decrease !== 0) {
    filterRequestObject.decrease = filter.decrease;
  }
  return filterRequestObject;
};

/**
 * Returns a boolean depending on if all filters are set or not
 */
export const checkIfFilterIsInactive = (filter: Filter) => {
  return (
    filter.gender === "" &&
    filter.location === "" &&
    filter.seniority === "" &&
    filter.status === "" &&
    !filter.responsibility &&
    !filter.promotion &&
    filter.increase === 0 &&
    filter.decrease === 0
  );
};

export const getLatestSalaryHistory = (employee: Employee) =>
  employee.salaryHistoryWithStatusReleased.reduce(
    (latest: SalaryHistory | null, current) => {
      const effectiveFromDate = new Date(current.effectiveFrom);

      if (
        !latest ||
        (latest.effectiveFrom &&
          effectiveFromDate > new Date(latest.effectiveFrom))
      ) {
        return current;
      }

      return latest;
    },
    null,
  );

export interface EmployeeUpnNameMap {
  upn: string;
  name: string;
}

export const findEmployeesWithAction = (
  employee: Employee,
  availableActionToFind: string,
): EmployeeUpnNameMap[] => {
  const result: EmployeeUpnNameMap[] = [];
  if (employee.availableActions === availableActionToFind) {
    result.push({
      upn: employee.upn,
      name: `${employee.firstName} ${employee.lastName}`, // Combine firstName and lastName
    });
  }

  if (employee.team) {
    result.push(
      ...findEmployeesFromArrayWithAction(employee.team, availableActionToFind),
    );
  }

  return result;
};

export const findEmployeesFromArrayWithAction = (
  employees: Employee[],
  availableActionToFind: string,
) => {
  const result = [];
  if (employees && Array.isArray(employees) && employees.length > 0) {
    for (const subEmployee of employees) {
      result.push(
        ...findEmployeesWithAction(subEmployee, availableActionToFind),
      );
    }
  }
  return result;
};

export function areAllPropertiesDefined(
  obj: Record<string, any>,
  forceIgnore?: boolean,
): boolean {
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      if (obj[key] === undefined) {
        if (!forceIgnore) {
          console.log(`Property "${key}" for user "${obj.upn}" is undefined.`);
        }
        if (
          !(
            (key === "projectQuota" && !!obj.costRelief) ||
            (key === "costRelief" && !!obj.projectQuota) ||
            forceIgnore
          )
        ) {
          return false; // Return false if any property is undefined
        }
      }
    }
  }
  return true; // Return true if all properties are defined
}

export const generateActionMessageBody = (
  header: string,
  type: string,
  employeeMap: EmployeeUpnNameMap[],
) => {
  return (
    <div>
      <span>{header}</span>
      <br />
      <br />
      <ul style={{ maxHeight: "400px", overflowY: "auto" }}>
        {employeeMap?.map((e) => (
          <li key={"employee-" + type + e.upn}>{e.name}</li>
        ))}
      </ul>
    </div>
  );
};
