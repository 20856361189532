interface FullNameProps {
  firstName: string;
  lastName: string;
  twoLine?: boolean;
}

const FullName = ({ firstName, lastName, twoLine }: FullNameProps) => (
  <>
    {firstName}
    {twoLine ? <br /> : " "}
    {lastName}
  </>
);

export default FullName;
