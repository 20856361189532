import { useAccount, useIsAuthenticated, useMsal } from "@azure/msal-react";

import Logo from "components/base/Logo";
import IconExit from "components/icons/IconExit";
import Switch from "./Switch";
import { useLanguage } from "../../contexts/LanguageContext";

const Header = () => {
  const msal = useMsal();
  const account = useAccount();
  const authenticated = useIsAuthenticated();

  const { messages, language, setLanguage } = useLanguage();

  return (
    <header>
      <div className="flex-row-items-center">
        <Logo />
        <h1 style={{ marginLeft: "16px", marginBottom: "1px" }}>
          Benefits Approval
        </h1>
      </div>

      {authenticated && (
        <>
          <div className="header-right">
            <div className="flex-col-items-content-center">
              <span>{account?.username}</span>
              <div className="flex-row">
                <span>
                  {messages.language}:{" "}
                  {language.toString().substring(0, 2).toUpperCase()}
                </span>
                <Switch
                  onClick={() => {
                    setLanguage((prevState) => {
                      localStorage.setItem(
                        "language",
                        prevState === "en" ? "de-DE" : "en",
                      );
                      return prevState === "en" ? "de-DE" : "en";
                    });
                  }}
                  checked={!language.toLowerCase().includes("de")}
                />
              </div>
            </div>
            <button onClick={() => msal.instance.logout()}>
              <IconExit />
            </button>
          </div>
        </>
      )}
    </header>
  );
};

export default Header;
