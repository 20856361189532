const IconChartLine = ({ size = 24 }) => (
  <svg width={size} height={size} viewBox="0 0 48 48">
    <polyline
      points="44 16 30 30 20 20 10 30"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
    <polyline
      points="3 8 3 45 46 45"
      fill="none"
      stroke="currentColor"
      strokeMiterlimit="10"
      strokeWidth="2"
    />
  </svg>
);

export default IconChartLine;
