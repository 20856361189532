import { Employee, SalaryHistory } from "types/Employee";
import LabeledAmount from "components/base/LabeledAmount";
import round from "../../shared/round";
import { useLanguage } from "../../contexts/LanguageContext";

interface EmployeeTargetSalaryProps {
  employee: Employee;
}

const EmployeeTargetSalary = ({ employee }: EmployeeTargetSalaryProps) => {
  const { messages } = useLanguage();

  const getSalaryNormed = (record: SalaryHistory | undefined) => {
    if (
      record?.fixum !== undefined &&
      record?.variable !== undefined &&
      record?.hours !== undefined
    ) {
      return (
        ((record.fixum + record.variable) / record.hours) * 40 +
        record.companyCar
      );
    } else {
      return undefined;
    }
  };

  const getCurrentSalaryNormed = () => {
    return getSalaryNormed(employee.currentSalaryHistory);
  };

  const getPreviousSalaryNormed = () => {
    return getSalaryNormed(employee.lastSalaryHistory ?? undefined);
  };

  const getAbsoluteIncrease = () => {
    const current = getCurrentSalaryNormed();
    const previous = getPreviousSalaryNormed();
    if (current !== undefined && previous !== undefined) {
      return current - previous;
    } else {
      return undefined;
    }
  };

  const getPercentIncrease = () => {
    const previous = getPreviousSalaryNormed();
    const absIncrease = getAbsoluteIncrease();
    if (previous !== undefined && absIncrease !== undefined) {
      return (absIncrease / previous) * 100;
    } else {
      return undefined;
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div className="employee-target-salary">
        <h2 className="mb-16">{messages.targetSalaryLabel}</h2>
        <div className="employee-target-salary-items">
          <LabeledAmount
            label={messages.plannedNormedSalary + " " + messages.usedYear}
            amount={getCurrentSalaryNormed()}
            currency={employee.lastSalaryHistory?.currency ?? "EUR"}
          />
          <LabeledAmount
            label={messages.absoluteIncrease}
            amount={getAbsoluteIncrease()}
            currency={employee.lastSalaryHistory?.currency ?? "EUR"}
          />
          <LabeledAmount
            inPercentage
            increaseWarning={
              getPercentIncrease() !== undefined &&
              round(getPercentIncrease()!, 0) > 10
            }
            label={messages.percentageIncrease}
            amount={getPercentIncrease()}
            currency={employee.lastSalaryHistory?.currency ?? "EUR"}
          />
        </div>
        <div className="employee-target-salary-items">
          <LabeledAmount
            label={
              messages.plannedSinglePayment + " " + messages.usedYear
            }
            amount={employee.currentSalaryHistory?.singlePayment ?? undefined}
            currency={employee.lastSalaryHistory?.currency ?? "EUR"}
          />
          <LabeledAmount
            inPercentage
            label={
              messages.plannedVariablePortion + " " + messages.usedYear
            }
            amount={employee.currentSalaryHistory?.variablePercent ?? undefined}
            currency={employee.lastSalaryHistory?.currency ?? "EUR"}
          />
          <div className="labeled"></div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeTargetSalary;
