import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { EmployeeEditable, SalaryHistory } from "../../types/Employee";
import Seniority from "../../types/Seniority";
import { capitalizeFirstLetter } from "../../utils/helper";
import { Degree } from "../../types/Degree";

export const onChangeCurrentTargetValueNumber = (
  setEditableSalaryHistory: Dispatch<SetStateAction<EmployeeEditable>>,
  event: ChangeEvent<HTMLInputElement>,
  key: keyof EmployeeEditable,
) => {
  const numValue = Number(event.currentTarget.value);
  setEditableSalaryHistory((prevState) => ({
    ...prevState,
    [`${key}`]: numValue,
  }));
};

export const onChangeCurrentTargetValueString = (
  setEditableSalaryHistory: Dispatch<SetStateAction<EmployeeEditable>>,
  event: ChangeEvent<HTMLInputElement>,
  key: keyof EmployeeEditable,
) => {
  const stringValue = event.currentTarget.value;
  setEditableSalaryHistory((prevState) => ({
    ...prevState,
    [`${key}`]: stringValue,
  }));
};

export const onChangeInvertBoolean = (
  editableSalaryHistory: EmployeeEditable,
  setEditableSalaryHistory: Dispatch<SetStateAction<EmployeeEditable>>,
  key: keyof EmployeeEditable,
) => {
  setEditableSalaryHistory((prevState) => ({
    ...prevState,
    [`${key}`]: !editableSalaryHistory[key],
  }));
};

export const onChangeSeniority = (
  setEditableSalaryHistory: Dispatch<SetStateAction<EmployeeEditable>>,
  event: ChangeEvent<HTMLSelectElement>,
) => {
  const seniorityValue = event.currentTarget.value as keyof typeof Seniority;
  setEditableSalaryHistory((prevState) => ({
    ...prevState,
    seniority: seniorityValue,
  }));
};

export const onChangeDegree = (
  setEditableSalaryHistory: Dispatch<SetStateAction<EmployeeEditable>>,
  event: ChangeEvent<HTMLSelectElement>,
) => {
  const degreeValue = event.currentTarget
    .value as keyof typeof Degree as string;
  setEditableSalaryHistory((prevState) => ({
    ...prevState,
    degree: degreeValue,
  }));
};

export const getValueNormed = (value: number, hours: number) => {
  return (value / hours) * 40;
};

export const getValueToNewHours = (
  value: number,
  oldHours: number,
  newHours: number,
) => {
  return (value / oldHours) * newHours;
};

export const onChangeNormed = (
  type: string,
  event: ChangeEvent<HTMLInputElement>,
  editableSalaryHistory: EmployeeEditable,
  setEditableSalaryHistory: Dispatch<SetStateAction<EmployeeEditable>>,
  lastSalaryHistory: SalaryHistory | null,
  setData: Dispatch<
    SetStateAction<{
      normed?: number;
      actual?: number;
      absolute?: number;
      percent?: number;
    }>
  >,
) => {
  const normed = Number(event.currentTarget.value);
  const actual = (normed / 40) * editableSalaryHistory.hours;
  const absolute =
    lastSalaryHistory && (lastSalaryHistory as any)[type] !== undefined
      ? normed -
        getValueNormed(
          (lastSalaryHistory as any)[type],
          lastSalaryHistory.hours,
        )
      : undefined;
  const percent =
    lastSalaryHistory &&
    (lastSalaryHistory as any)[type] &&
    absolute !== undefined
      ? (absolute /
          getValueNormed(
            (lastSalaryHistory as any)[type],
            lastSalaryHistory.hours,
          )) *
        100
      : undefined;

  setData(() => ({
    normed,
    actual,
    absolute,
    percent,
  }));

  setEditableSalaryHistory((prevState) => ({
    ...prevState,
    [`normed${capitalizeFirstLetter(type)}`]: normed,
    [`${type}`]: actual,
  }));
};

export const onChangeActual = (
  type: string,
  event: ChangeEvent<HTMLInputElement>,
  editableSalaryHistory: EmployeeEditable,
  setEditableSalaryHistory: Dispatch<SetStateAction<EmployeeEditable>>,
  lastSalaryHistory: SalaryHistory | null,
  setData: Dispatch<
    SetStateAction<{
      normed?: number;
      actual?: number;
      absolute?: number;
      percent?: number;
    }>
  >,
) => {
  const actual = Number(event.currentTarget.value);
  const normed = (actual / editableSalaryHistory.hours) * 40;
  const absolute =
    lastSalaryHistory && (lastSalaryHistory as any)[type] !== undefined
      ? normed -
        getValueNormed(
          (lastSalaryHistory as any)[type],
          lastSalaryHistory.hours,
        )
      : undefined;
  const percent =
    lastSalaryHistory &&
    (lastSalaryHistory as any)[type] &&
    absolute !== undefined
      ? (absolute /
          getValueNormed(
            (lastSalaryHistory as any)[type],
            lastSalaryHistory.hours,
          )) *
        100
      : undefined;

  setData(() => ({
    normed,
    actual,
    absolute,
    percent,
  }));

  setEditableSalaryHistory((prevState) => ({
    ...prevState,
    [`normed${capitalizeFirstLetter(type)}`]: normed,
    [`${type}`]: actual,
  }));
};

export const onChangeAbsolute = (
  type: string,
  event: ChangeEvent<HTMLInputElement>,
  editableSalaryHistory: EmployeeEditable,
  setEditableSalaryHistory: Dispatch<SetStateAction<EmployeeEditable>>,
  lastSalaryHistory: SalaryHistory | null,
  setData: Dispatch<
    SetStateAction<{
      normed?: number;
      actual?: number;
      absolute?: number;
      percent?: number;
    }>
  >,
) => {
  const absolute = Number(event.currentTarget.value);
  const normed =
    lastSalaryHistory && (lastSalaryHistory as any)[type] !== undefined
      ? getValueNormed(
          (lastSalaryHistory as any)[type],
          lastSalaryHistory.hours,
        ) + absolute
      : undefined;
  const actual =
    normed && lastSalaryHistory
      ? (normed / 40) * editableSalaryHistory.hours
      : undefined;
  const percent =
    lastSalaryHistory &&
    (lastSalaryHistory as any)[type] &&
    absolute !== undefined
      ? (absolute /
          getValueNormed(
            (lastSalaryHistory as any)[type],
            lastSalaryHistory.hours,
          )) *
        100
      : undefined;

  setData(() => ({
    normed,
    actual,
    absolute,
    percent,
  }));

  setEditableSalaryHistory((prevState) => ({
    ...prevState,
    [`normed${capitalizeFirstLetter(type)}`]: normed,
    [`${type}`]: actual,
  }));
};

export const onChangePercent = (
  type: string,
  event: ChangeEvent<HTMLInputElement>,
  editableSalaryHistory: EmployeeEditable,
  setEditableSalaryHistory: Dispatch<SetStateAction<EmployeeEditable>>,
  lastSalaryHistory: SalaryHistory | null,
  setData: Dispatch<
    SetStateAction<{
      normed?: number;
      actual?: number;
      absolute?: number;
      percent?: number;
    }>
  >,
) => {
  const percent = Number(event.currentTarget.value);
  const absolute =
    lastSalaryHistory &&
    (lastSalaryHistory as any)[type] &&
    percent !== undefined
      ? getValueToNewHours(
          (lastSalaryHistory as any)[type],
          lastSalaryHistory.hours,
          editableSalaryHistory.hours,
        ) *
        (percent / 100)
      : undefined;
  const actual =
    lastSalaryHistory &&
    (lastSalaryHistory as any)[type] &&
    absolute !== undefined
      ? getValueToNewHours(
          (lastSalaryHistory as any)[type],
          lastSalaryHistory.hours,
          editableSalaryHistory.hours,
        ) + absolute
      : undefined;
  const normed =
    actual !== undefined
      ? (actual / editableSalaryHistory.hours) * 40
      : undefined;

  setData(() => ({
    normed,
    actual,
    absolute,
    percent,
  }));

  setEditableSalaryHistory((prevState) => ({
    ...prevState,
    [`normed${capitalizeFirstLetter(type)}`]: normed,
    [`${type}`]: actual,
  }));
};

export const getDecimalValue = (value: number, percent?: boolean) =>
  value.toLocaleString(undefined, {
    minimumFractionDigits: percent ? 1 : 2,
    maximumFractionDigits: percent ? 1 : 2,
  });
