import Labeled from "components/base/Labeled";
import NumberInput from "components/base/NumberInput";

interface LabeledAmountProps {
  className?: string;
  amount?: number;
  editable?: boolean;
  increaseWarning?: boolean;
  inPercentage?: boolean;
  label: React.ReactNode;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  suffix?: string;
  currency?: string;
  unsetHeight?: boolean;
}

const LabeledAmount = ({
  className,
  amount,
  editable,
  label,
  increaseWarning,
  inPercentage,
  onChange,
  currency,
  suffix = inPercentage ? "%" : currency,
  unsetHeight,
}: LabeledAmountProps) => (
  <Labeled
    className={"labeled-amount " + className}
    editable={editable}
    label={label}
    suffix={amount !== undefined ? suffix : ""}
    text={amount !== undefined ? amount : "N/A"}
    warning={increaseWarning}
    style={{ height: unsetHeight ? "unset" : undefined }}
  >
    {amount !== undefined && (
      <NumberInput value={amount} onChangeNumber={onChange} />
    )}
  </Labeled>
);

export default LabeledAmount;
