import IconLoading from "../icons/IconLoading";

export const LoadingOverlay = () => (
  <div
    style={{
      backgroundColor: "rgba(0,0,0,.25)",
      position: "fixed",
      width: "100vw",
      height: "100vh",
      top: 0,
      left: 0,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    }}
  >
    <div className="flex-col-items-content-center mt-32">
      <IconLoading dimension={92} color="grey" />
    </div>
  </div>
);
