import Card from "components/base/Card";
import { EmployeeDetails, SalaryHistory } from "types/Employee";
import Labeled from "components/base/Labeled";
import Seniority from "types/Seniority";
import { Tooltip } from "react-tooltip";
import { ModalProperties } from "../../types/ModalProperties";
import { seniorityMessages } from "../../shared/messages";
import { useLanguage } from "../../contexts/LanguageContext";
import { getDecimalValue } from "./functions";
import SalaryHistoryDetail from "./EmployeeSalaryHistoryDetail";

interface SalaryHistoryProps {
  history: SalaryHistory;
  setModalProperties?: (modalProperties: ModalProperties) => void;
}

interface EmployeeSalaryHistoryProps {
  employee: EmployeeDetails;
  setModalProperties?: (modalProperties: ModalProperties) => void;
}

const getLeadLevel = (level: string) =>
  seniorityMessages[level as keyof typeof Seniority].label;

const getNormedSalary = (history: SalaryHistory) =>
  ((history.fixum + history.variable) / history.hours) * 40 +
  (history.companyCar ?? 0);

const SalaryHistoryComponent = ({
  history,
  setModalProperties,
}: SalaryHistoryProps) => {
  const { messages } = useLanguage();

  const increaseText = history.difference
    ? `, ${messages.percentageIncrease}: ${getDecimalValue(
        history.difference,
      )}%`
    : "";
  const tooltipContent = `${messages.information.approvalDate}: ${new Date(
    history.teamLeaderApprovalDate,
  ).toLocaleDateString()}, ${messages.information.effectiveFrom}: ${new Date(
    history.effectiveFrom,
  ).toLocaleDateString()}, ${messages.comment}: ${
    history.reason
  }${increaseText}`;

  const handleClickHistory = () => {
    if (setModalProperties) {
      setModalProperties({
        visible: true,
        header: `${messages.information.history} ${new Date(
          history.effectiveFrom,
        ).toLocaleDateString()}`,
        content: <SalaryHistoryDetail history={history} />,
        showCloseButton: true,
        confirmButtonText: "OK",
        confirmButtonAction: undefined,
      });
    }
  };

  return (
    <div
      className="employee-salary-history-list-item"
      data-tooltip-id="employee-salary-history-list-item"
      data-tooltip-content={tooltipContent}
    >
      <Tooltip id="employee-salary-history-list-item" />
      <strong>{String(new Date(history.effectiveFrom).getFullYear())}</strong>
      <div className="employee-salary-history-line">
        <button
          className="employee-salary-history-circle-button"
          onClick={() => handleClickHistory()}
        />
      </div>
      <Labeled
        suffix={history.currency}
        label={getLeadLevel(history.seniority)}
        text={getNormedSalary(history)}
      />
      <div className="employee-salary-history-difference">
        {history.difference
          ? `(${getDecimalValue(history.difference, true)}%)`
          : ""}
      </div>
    </div>
  );
};

const getSortedSalaryHistory = (employee: EmployeeDetails, count?: number) => {
  return (
    JSON.parse(
      JSON.stringify(employee.salaryHistoryWithStatusReleased),
    ) as SalaryHistory[]
  )
    .sort((a, b) => {
      const dateA = new Date(a.effectiveFrom);
      const dateB = new Date(b.effectiveFrom);
      if (dateA === dateB) {
        return 0;
      } else if (dateA < dateB) {
        return 1;
      } else {
        return -1;
      }
    })
    .slice(0, count ?? employee.salaryHistoryWithStatusReleased.length);
};

const EmployeeSalaryHistory = ({
  employee,
  setModalProperties,
}: EmployeeSalaryHistoryProps) => {
  const { messages } = useLanguage();
  return (
    <Card className="employee-salary-history">
      <h2 style={{ marginBottom: 0 }}>{messages.salaryHistory}</h2>
      <div className="employee-salary-history-list">
        {getSortedSalaryHistory(employee).map((history) => (
          <SalaryHistoryComponent
            key={"salary-history-" + String(new Date(history.effectiveFrom))}
            history={history}
            setModalProperties={setModalProperties}
          />
        ))}
      </div>
    </Card>
  );
};

export default EmployeeSalaryHistory;
