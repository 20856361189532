import { useState } from "react";

import NotificationContext, {
  Notification,
} from "contexts/NotificationContext";

const NotificationContextProvider = ({ children }: React.PropsWithChildren) => {
  const [notification, setNotification] = useState<Notification>();

  return (
    <NotificationContext.Provider value={[notification, setNotification]}>
      {children}
    </NotificationContext.Provider>
  );
};

export default NotificationContextProvider;
