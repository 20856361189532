import React, {
  createContext,
  Dispatch,
  SetStateAction,
  useContext,
  useState,
} from "react";
import { messages_de, messages_en } from "../shared/messages";

interface LanguageContextType {
  language: string;
  setLanguage: Dispatch<SetStateAction<string>>;
  messages: any;
}

const LanguageContext = createContext<LanguageContextType | undefined>(
  undefined,
);

export function useLanguage() {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error("useLanguage must be used within a LanguageProvider");
  }
  return context;
}

export function LanguageProvider({ children }: { children: React.ReactNode }) {
  const [language, setLanguage] = useState(
    localStorage.getItem("language") ||
      (navigator.language.includes("de") ? "de-DE" : "en"),
  );
  const messages = language.toLowerCase().includes("de")
    ? messages_de
    : messages_en;

  return (
    <LanguageContext.Provider value={{ language, setLanguage, messages }}>
      {children}
    </LanguageContext.Provider>
  );
}
