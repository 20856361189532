import { Link } from "react-router-dom";

const PageNotFound = () => (
  <div className="page-not-found">
    <h1>This page can't be found.</h1>
    <p>The page you are looking for no longer exists.</p>
    <Link to="/">Go back to homepage</Link>
  </div>
);

export default PageNotFound;
