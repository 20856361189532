import clsx from "clsx";

interface ActionButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  warning?: boolean;
  action?: boolean;
  label?: string;
  onClick?: () => Promise<void>;
}

const ActionButton = ({
  children,
  className,
  label,
  onClick,
  warning,
  action,
  ...rest
}: ActionButtonProps) => (
  <div
    className={clsx("action-button", className, {
      "action-button-warning": warning,
      "action-button-action": action,
    })}
  >
    <button {...rest} onClick={onClick}>
      {children}
    </button>
    <strong>{label}</strong>
  </div>
);

export default ActionButton;
