import { EmployeeDetails, EmployeeEditable } from "types/Employee";
import Card from "components/base/Card";
import LabeledAmount from "components/base/LabeledAmount";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import {
  getValueNormed,
  onChangeAbsolute,
  onChangeActual,
  onChangeNormed,
  onChangePercent,
} from "./functions";
import { useLanguage } from "../../contexts/LanguageContext";

interface EmployeeSalaryProposalProps {
  employee: EmployeeDetails;
  editableSalaryHistory: EmployeeEditable;
  setEditableSalaryHistory: Dispatch<SetStateAction<EmployeeEditable>>;
}

const EmployeeSalaryProposal = ({
  employee,
  editableSalaryHistory,
  setEditableSalaryHistory,
}: EmployeeSalaryProposalProps) => {
  const { messages } = useLanguage();

  const editable = employee.availableActions === "APPROVE";

  const [fixumValues, setFixumValues] = useState<{
    normed?: number;
    actual?: number;
    absolute?: number;
    percent?: number;
  }>({
    normed: undefined,
    actual: undefined,
    absolute: undefined,
    percent: undefined,
  });

  const [variableValues, setVariableValues] = useState<{
    normed?: number;
    actual?: number;
    absolute?: number;
    percent?: number;
  }>({
    normed: undefined,
    actual: undefined,
    absolute: undefined,
    percent: undefined,
  });

  const absoluteIncreaseTotal = useMemo(
    () =>
      fixumValues.absolute !== undefined
        ? fixumValues.absolute + (variableValues.absolute ?? 0)
        : undefined,
    [fixumValues.absolute, variableValues.absolute],
  );

  const percentageIncreaseTotal = useMemo(
    () =>
      employee.lastSalaryHistory?.fixum && absoluteIncreaseTotal !== undefined
        ? (absoluteIncreaseTotal /
            (getValueNormed(
              employee.lastSalaryHistory.fixum,
              employee.lastSalaryHistory.hours,
            ) +
              getValueNormed(
                employee.lastSalaryHistory.variable ?? 0,
                employee.lastSalaryHistory.hours,
              ) +
              (employee.lastSalaryHistory.companyCar ?? 0))) *
          100
        : undefined,
    [
      absoluteIncreaseTotal,
      employee.lastSalaryHistory?.fixum,
      employee.lastSalaryHistory?.variable,
      employee.lastSalaryHistory?.hours,
      employee.lastSalaryHistory?.companyCar,
    ],
  );

  const calculateFixumAndVariable = () => {
    const absoluteFixum =
      employee.lastSalaryHistory?.fixum !== undefined
        ? editableSalaryHistory.normedFixum -
          getValueNormed(
            employee.lastSalaryHistory.fixum,
            employee.lastSalaryHistory.hours,
          )
        : 0;
    const absoluteVariable =
      employee.lastSalaryHistory?.variable !== undefined
        ? editableSalaryHistory.normedVariable -
          getValueNormed(
            employee.lastSalaryHistory.variable,
            employee.lastSalaryHistory.hours,
          )
        : 0;

    setFixumValues(() => {
      return {
        normed: editableSalaryHistory.normedFixum,
        actual: editableSalaryHistory.fixum,
        absolute: absoluteFixum,
        percent:
          employee.lastSalaryHistory?.fixum && absoluteFixum !== undefined
            ? (absoluteFixum /
                getValueNormed(
                  employee.lastSalaryHistory.fixum,
                  employee.lastSalaryHistory.hours,
                )) *
              100
            : undefined,
      };
    });

    setVariableValues(() => {
      return {
        normed: editableSalaryHistory.normedVariable,
        actual: editableSalaryHistory.variable,
        absolute: absoluteVariable,
        percent:
          employee.lastSalaryHistory?.variable && absoluteVariable !== undefined
            ? (absoluteVariable /
                getValueNormed(
                  employee.lastSalaryHistory.variable,
                  employee.lastSalaryHistory.hours,
                )) *
              100
            : undefined,
      };
    });
  };

  useEffect(() => {
    if (
      fixumValues.normed === undefined ||
      variableValues.normed === undefined
    ) {
      calculateFixumAndVariable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    fixumValues.normed,
    variableValues.normed,
    employee.lastSalaryHistory,
    editableSalaryHistory,
  ]);

  useEffect(() => {
    calculateFixumAndVariable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editableSalaryHistory]);

  return (
    <Card className="employee-salary-proposal">
      <div
        className="employee-salary-proposal-header employee-salary-proposal-header-grey"
        style={{
          height: !editableSalaryHistory.companyCar ? "unset" : undefined,
          borderBottom: !editableSalaryHistory.companyCar ? "unset" : undefined,
        }}
      >
        {messages.fixum}
      </div>
      <LabeledAmount
        className="employee-salary-proposal-cell-grey"
        editable={editable}
        amount={fixumValues.normed}
        label={messages.plannedNormedSalary + " " + messages.usedYear}
        onChange={(event) =>
          onChangeNormed(
            "fixum",
            event,
            editableSalaryHistory,
            setEditableSalaryHistory,
            employee.lastSalaryHistory ?? null,
            setFixumValues,
          )
        }
        currency={editableSalaryHistory.currency}
        unsetHeight={!editableSalaryHistory.companyCar}
      />
      <LabeledAmount
        className="employee-salary-proposal-cell-grey"
        editable={editable}
        amount={fixumValues.actual}
        label={messages.plannedActualSalary + " " + messages.usedYear}
        onChange={(event) =>
          onChangeActual(
            "fixum",
            event,
            editableSalaryHistory,
            setEditableSalaryHistory,
            employee.lastSalaryHistory ?? null,
            setFixumValues,
          )
        }
        currency={editableSalaryHistory.currency}
        unsetHeight={!editableSalaryHistory.companyCar}
      />
      <LabeledAmount
        className="employee-salary-proposal-cell-grey"
        editable={editable}
        onChange={(event) =>
          onChangeAbsolute(
            "fixum",
            event,
            editableSalaryHistory,
            setEditableSalaryHistory,
            employee.lastSalaryHistory ?? null,
            setFixumValues,
          )
        }
        amount={fixumValues.absolute}
        label={messages.absoluteIncrease}
        currency={editableSalaryHistory.currency}
        unsetHeight={!editableSalaryHistory.companyCar}
      />
      <LabeledAmount
        className="employee-salary-proposal-cell-grey"
        inPercentage
        editable={editable}
        onChange={(event) =>
          onChangePercent(
            "fixum",
            event,
            editableSalaryHistory,
            setEditableSalaryHistory,
            employee.lastSalaryHistory ?? null,
            setFixumValues,
          )
        }
        amount={fixumValues.percent}
        label={messages.percentageIncrease}
        unsetHeight={!editableSalaryHistory.companyCar}
      />

      <div
        className="employee-salary-proposal-header"
        style={{
          height: !editableSalaryHistory.companyCar ? "unset" : undefined,
          borderBottom: !editableSalaryHistory.companyCar ? "unset" : undefined,
        }}
      >
        {messages.variable}
      </div>
      <LabeledAmount
        editable={editable}
        amount={variableValues.normed}
        label={messages.plannedNormedSalary + " " + messages.usedYear}
        onChange={(event) =>
          onChangeNormed(
            "variable",
            event,
            editableSalaryHistory,
            setEditableSalaryHistory,
            employee.lastSalaryHistory ?? null,
            setVariableValues,
          )
        }
        currency={editableSalaryHistory.currency}
        unsetHeight={!editableSalaryHistory.companyCar}
      />
      <LabeledAmount
        editable={editable}
        amount={variableValues.actual}
        label={messages.plannedActualSalary + " " + messages.usedYear}
        onChange={(event) =>
          onChangeActual(
            "variable",
            event,
            editableSalaryHistory,
            setEditableSalaryHistory,
            employee.lastSalaryHistory ?? null,
            setVariableValues,
          )
        }
        currency={editableSalaryHistory.currency}
        unsetHeight={!editableSalaryHistory.companyCar}
      />
      <LabeledAmount
        editable={editable}
        amount={variableValues.absolute}
        onChange={(event) =>
          onChangeAbsolute(
            "variable",
            event,
            editableSalaryHistory,
            setEditableSalaryHistory,
            employee.lastSalaryHistory ?? null,
            setVariableValues,
          )
        }
        label={messages.absoluteIncrease}
        currency={editableSalaryHistory.currency}
        unsetHeight={!editableSalaryHistory.companyCar}
      />
      <LabeledAmount
        inPercentage
        editable={editable}
        amount={variableValues.percent}
        onChange={(event) =>
          onChangePercent(
            "variable",
            event,
            editableSalaryHistory,
            setEditableSalaryHistory,
            employee.lastSalaryHistory ?? null,
            setVariableValues,
          )
        }
        label={messages.percentageIncrease}
        unsetHeight={!editableSalaryHistory.companyCar}
      />

      {!!editableSalaryHistory.companyCar && (
        <>
          <div className="employee-salary-proposal-header employee-salary-proposal-header-grey">
            <span>{messages.companyCarReverted}</span>
          </div>
          <LabeledAmount
            className="employee-salary-proposal-cell-grey"
            amount={editableSalaryHistory.companyCar}
            label={messages.plannedActualCost + " " + messages.usedYear}
            currency={editableSalaryHistory.currency}
            unsetHeight={!editableSalaryHistory.companyCar}
          />
          <div
            className="employee-salary-proposal-cell-grey"
            style={{ borderBottom: "2px solid #DADADA" }}
          />
          <div
            className="employee-salary-proposal-cell-grey"
            style={{ borderBottom: "2px solid #DADADA" }}
          />
          <div
            className="employee-salary-proposal-cell-grey"
            style={{ borderBottom: "2px solid #DADADA" }}
          />
        </>
      )}

      <div
        className="employee-salary-proposal-header employee-salary-proposal-header-target"
        style={{
          height: !editableSalaryHistory.companyCar ? "unset" : undefined,
          borderBottom: !editableSalaryHistory.companyCar ? "unset" : undefined,
        }}
      >
        {messages.target}
      </div>
      <LabeledAmount
        className="employee-salary-proposal-cell-target"
        amount={
          editableSalaryHistory.normedFixum +
          editableSalaryHistory.normedVariable +
          (editableSalaryHistory.companyCar ?? 0)
        }
        label={messages.plannedNormedSalary + " " + messages.usedYear}
        currency={editableSalaryHistory.currency}
        unsetHeight={!editableSalaryHistory.companyCar}
      />
      <LabeledAmount
        className="employee-salary-proposal-cell-target"
        amount={
          editableSalaryHistory.fixum +
          editableSalaryHistory.variable +
          (editableSalaryHistory.companyCar ?? 0)
        }
        label={messages.plannedActualSalary + " " + messages.usedYear}
        currency={editableSalaryHistory.currency}
        unsetHeight={!editableSalaryHistory.companyCar}
      />
      <LabeledAmount
        className="employee-salary-proposal-cell-target"
        amount={absoluteIncreaseTotal}
        label={messages.absoluteIncrease}
        currency={editableSalaryHistory.currency}
        unsetHeight={!editableSalaryHistory.companyCar}
      />
      <LabeledAmount
        className="employee-salary-proposal-cell-target"
        inPercentage
        amount={percentageIncreaseTotal}
        label={messages.percentageIncrease}
        unsetHeight={!editableSalaryHistory.companyCar}
      />
    </Card>
  );
};

export default EmployeeSalaryProposal;
