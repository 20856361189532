import IconCross from "../icons/IconCross";

interface SelectProps {
  caption: string;
  changeFn: (eventTargetValue: string) => void;
  optionObject: { [key: string]: string };
  selected: string;
}

const FilterUI = ({
  caption,
  changeFn,
  optionObject,
  selected,
}: SelectProps) => {
  const isSelected = selected !== "";

  return (
    <div className="flex-column-content-center">
      {isSelected && <small className="mb-4">{caption}</small>}
      <div className="flex-row-items-center filter-width">
        <select
          className={`select-custom${
            isSelected ? " select-custom-selected" : ""
          }`}
          onChange={(e) => changeFn(e.target.value)}
          value={selected}
        >
          {!isSelected && (
            <option key="default" value={caption}>
              {caption}
            </option>
          )}
          {Object.keys(optionObject).map((option) => (
            <option key={option} value={option}>
              {optionObject[option]}
            </option>
          ))}
        </select>
        {isSelected ? (
          <div
            style={{
              marginBottom: "17px",
              marginLeft: "8px",
              cursor: "pointer",
              marginTop: "2px",
              width: "18px",
            }}
            onClick={() => changeFn("")}
          >
            <IconCross />
          </div>
        ) : (
          <div style={{ marginLeft: "8px", width: "18px" }} />
        )}
      </div>
    </div>
  );
};

export default FilterUI;
