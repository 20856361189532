const IconLocation = () => (
  <svg width="16" height="16" viewBox="0 0 16 16">
    <path
      d="M8.00144 7.83325C8.32268 7.83325 8.59718 7.71887 8.82496 7.4901C9.05274 7.26135 9.16663 6.98635 9.16663 6.6651C9.16663 6.34387 9.05224 6.06936 8.82348 5.84159C8.59472 5.61381 8.31972 5.49992 7.99848 5.49992C7.67724 5.49992 7.40274 5.6143 7.17496 5.84307C6.94718 6.07182 6.83329 6.34682 6.83329 6.66807C6.83329 6.9893 6.94768 7.26381 7.17644 7.49158C7.4052 7.71936 7.6802 7.83325 8.00144 7.83325ZM7.99996 13.3499C9.47774 12.0055 10.5694 10.786 11.275 9.69158C11.9805 8.59714 12.3333 7.63325 12.3333 6.79992C12.3333 5.49114 11.915 4.4195 11.0784 3.585C10.2419 2.7505 9.2157 2.33325 7.99996 2.33325C6.78421 2.33325 5.75806 2.7505 4.92149 3.585C4.08491 4.4195 3.66663 5.49114 3.66663 6.79992C3.66663 7.63325 4.02774 8.59714 4.74996 9.69158C5.47218 10.786 6.55552 12.0055 7.99996 13.3499ZM7.99996 14.6666C6.21107 13.1444 4.87496 11.7305 3.99163 10.4249C3.10829 9.11936 2.66663 7.91103 2.66663 6.79992C2.66663 5.13325 3.20274 3.80547 4.27496 2.81659C5.34718 1.8277 6.58885 1.33325 7.99996 1.33325C9.41107 1.33325 10.6527 1.8277 11.725 2.81659C12.7972 3.80547 13.3333 5.13325 13.3333 6.79992C13.3333 7.91103 12.8916 9.11936 12.0083 10.4249C11.125 11.7305 9.78885 13.1444 7.99996 14.6666Z"
      fill="currentColor"
    />
  </svg>
);

export default IconLocation;
