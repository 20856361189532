import endpoints from "../shared/endpoints";
import { Employee, EmployeeDetails, Statistics } from "../types/Employee";

export const getWhoami = (headers: HeadersInit | undefined) =>
  fetch(endpoints.whoami, { method: "GET", headers }).then(
    async (response) => (await response.json()) as string,
  );

export const getEmployeesByRoleWithFilter = (
  role: string,
  headers: HeadersInit | undefined,
  body: any,
) =>
  fetch(endpoints.employees(role), {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  }).then(async (response) => (await response.json()) as Employee[]);

export const getEmployeeDetailByUPN = (
  employeeUPN: string,
  headers: HeadersInit | undefined,
) =>
  fetch(endpoints.employeeDetail(employeeUPN), { method: "GET", headers }).then(
    async (response) => (await response.json()) as EmployeeDetails,
  );

export const updateEmployeeDetailByUPN = (
  employeeUPN: string,
  headers: HeadersInit | undefined,
  body: any,
) =>
  fetch(endpoints.employeeDetail(employeeUPN), {
    method: "PUT",
    headers,
    body: JSON.stringify(body),
  }).then(async () => true);

export const postApproval = (
  employeeUPN: string,
  headers: HeadersInit | undefined,
) =>
  fetch(endpoints.approveProposal(employeeUPN), {
    method: "POST",
    headers,
  }).then(async () => true);

export const postOvertake = (
  employeeUPN: string,
  headers: HeadersInit | undefined,
) =>
  fetch(endpoints.overtakeProposal(employeeUPN), {
    method: "POST",
    headers,
  }).then(async () => true);

export const postRelease = (
  employeeUPN: string,
  headers: HeadersInit | undefined,
) =>
  fetch(endpoints.releaseProposal(employeeUPN), {
    method: "POST",
    headers,
  }).then(async () => true);

export const getCompanyTree = (headers: HeadersInit | undefined) =>
  fetch(endpoints.companyTree(), { method: "GET", headers }).then(
    async (response) => (await response.json()) as any,
  );

export const getStatisticsWithFilter = (
  viewType: string,
  headers: HeadersInit | undefined,
  body: any,
) =>
  fetch(endpoints.statistics(viewType), {
    method: "POST",
    headers,
    body: JSON.stringify(body),
  }).then(async (response) => (await response.json()) as Statistics[]);
