import clsx from "clsx";

import IconIncrease from "components/icons/IconIncrease";

interface LabeledProps extends React.HTMLAttributes<HTMLDivElement> {
  editable?: boolean;
  label: React.ReactNode;
  suffix?: string;
  text?: string | number;
  warning?: boolean;
}

const Labeled = ({
  children,
  className,
  editable,
  label,
  suffix,
  text,
  warning,
  ...rest
}: LabeledProps) => (
  <div className={clsx("labeled", className)} {...rest}>
    <small>{label}</small>
    <div className={clsx("labeled-content", warning && "warning")}>
      {(editable && children) || (
        <strong>
          {text?.toLocaleString(undefined, {
            minimumFractionDigits: suffix === "%" ? 1 : 2,
            maximumFractionDigits: suffix === "%" ? 1 : 2,
          })}
        </strong>
      )}
      <strong>{suffix}</strong>
      {warning && <IconIncrease />}
    </div>
  </div>
);

export default Labeled;
