import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Employees from "./Employees";
import { useContext, useEffect, useState } from "react";
import RoleContext from "../../contexts/RoleContext";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  getCompanyTree,
  getEmployeesByRoleWithFilter,
  getStatisticsWithFilter,
} from "../../api";
import { Filter } from "../../types/Filter";
import useRequestHeader from "../../hooks/useRequestHeaders";
import { Employee } from "../../types/Employee";
import { checkIfFilterIsInactive, filterRequestValues } from "./functions";
import IconLoading from "../icons/IconLoading";
import ErrorMessage from "../base/ErrorMessage";
import Modal from "../base/Modal";
import { ModalProperties } from "../../types/ModalProperties";
import {
  filterObjectsWithProperty,
  mergeObjectByUpn,
  sortByTeamPresence,
} from "../../utils/helper";
import { useMsal } from "@azure/msal-react";
import { LoadingOverlay } from "../base/LoadingOverlay";
import { useLanguage } from "../../contexts/LanguageContext";
import { useNavigate } from "react-router-dom";

const EmployeesOverview = () => {
  const { messages } = useLanguage();

  const headers = useRequestHeader();
  const role = useContext(RoleContext)[0];
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const [dataLoaded, setDataLoaded] = useState(false);

  const [tabIndex, setTabIndex] = useState(
    localStorage.getItem("tabIndex")
      ? Number(localStorage.getItem("tabIndex"))
      : 1,
  );
  const [filteredEmployees, setFilteredEmployees] = useState([] as Employee[]);

  const [filter, setFilter] = useState({
    gender: "",
    location: "",
    seniority: "",
    status: "",
    responsibility: false,
    promotion: false,
    increase: 0,
  } as Filter);

  const [modalProperties, setModalProperties] = useState<ModalProperties>({
    visible: false,
    header: "",
    content: "",
    showCloseButton: true,
    confirmButtonText: "",
    confirmButtonAction: undefined,
  });

  const invalidateCache = () => {
    void queryClient.invalidateQueries({
      queryKey: ["employeesFiltered", filterRequestValues(filter), tabIndex],
    });
  };

  const { status: companyTreeQueryStatus, data: companyTreeQueryData } =
    useQuery(["companyTree"], () => getCompanyTree(headers));

  const {
    status: employeesQueryStatus,
    data: employeesQueryData,
    isFetching: employeeQueryFetching,
  } = useQuery(
    ["employeesFiltered", filterRequestValues(filter), tabIndex],
    () =>
      getEmployeesByRoleWithFilter(
        tabIndex === 0 ? role : "USER",
        headers,
        checkIfFilterIsInactive(filter) ? {} : filterRequestValues(filter),
      ),
  );

  const { status: statisticsQueryStatus, data: statisticsQueryData } = useQuery(
    ["statisticsFiltered", filterRequestValues(filter), tabIndex],
    () =>
      getStatisticsWithFilter(
        tabIndex === 0 ? role : "USER",
        headers,
        checkIfFilterIsInactive(filter) ? {} : filterRequestValues(filter),
      ),
  );

  const ownUpn = useMsal().instance.getActiveAccount()?.username.toLowerCase();

  useEffect(() => {
    if (
      employeesQueryStatus === "success" &&
      companyTreeQueryStatus === "success"
    ) {
      const copiedCompanyData = JSON.parse(
        JSON.stringify(companyTreeQueryData),
      );
      employeesQueryData?.forEach((employee) => {
        mergeObjectByUpn(copiedCompanyData, employee.upn, employee);
      });
      const filteredTree = filterObjectsWithProperty(copiedCompanyData);

      if (role === "USER" || (role !== "USER" && tabIndex === 1)) {
        let notFound = true;
        let currentEmployee = filteredTree[0];
        if (currentEmployee) {
          while (notFound) {
            if (
              currentEmployee.team?.find((e) => (e as Employee).birthDate) ||
              (ownUpn && currentEmployee.upn === ownUpn)
            ) {
              notFound = false;
              break;
            } else if (currentEmployee.team && currentEmployee.team.length) {
              if (!currentEmployee.team[0]) {
                notFound = false;
                break;
              }
              currentEmployee = currentEmployee.team[0];
            } else {
              navigate("/NoAccess");
              break;
            }
          }
        }

        setFilteredEmployees(
          currentEmployee
            ? sortByTeamPresence(currentEmployee.team as Employee[])
            : [],
        );
        setDataLoaded(true);
      } else {
        setFilteredEmployees(sortByTeamPresence(filteredTree as Employee[]));
        setDataLoaded(true);
      }
    }
  }, [
    tabIndex,
    companyTreeQueryData,
    employeesQueryData,
    employeesQueryStatus,
    companyTreeQueryStatus,
    role,
    ownUpn,
    navigate,
  ]);

  const employeeComponent = () => {
    return (
      <>
        {dataLoaded && (
          <>
            <Employees
              view={
                tabIndex === 0 && (role === "HR" || role === "BOARD")
                  ? role
                  : "USER"
              }
              employees={filteredEmployees}
              statistics={statisticsQueryData ?? []}
              filter={filter}
              setFilter={setFilter}
              setModalProperties={setModalProperties}
              refetchFn={invalidateCache}
              overtakeAvailable={!(role === "BOARD" && tabIndex === 0)}
              employeeStatisticsStatus={statisticsQueryStatus}
            />
            {employeeQueryFetching && <LoadingOverlay />}
          </>
        )}
        {!dataLoaded &&
          !(
            employeesQueryStatus === "error" ||
            statisticsQueryStatus === "error" ||
            companyTreeQueryStatus === "error"
          ) && (
            <div className="flex-col-items-content-center mt-32">
              <IconLoading />
            </div>
          )}
        {(employeesQueryStatus === "error" ||
          statisticsQueryStatus === "error" ||
          companyTreeQueryStatus === "error") && (
          <div className="flex-col-items-content-center mt-32">
            <ErrorMessage message={messages.fetchEmployeesFailed.content} />
          </div>
        )}
      </>
    );
  };

  return (
    <>
      {role === "HR" || role === "BOARD" ? (
        <section className="employee-overview">
          <Tabs
            selectedIndex={tabIndex}
            onSelect={(index) => {
              localStorage.setItem("tabIndex", index.toString(10));
              setTabIndex(index);
            }}
          >
            <TabList>
              <Tab>{role}</Tab>
              <Tab>USER</Tab>
            </TabList>
            <TabPanel>{employeeComponent()}</TabPanel>
            <TabPanel>{employeeComponent()}</TabPanel>
          </Tabs>
        </section>
      ) : (
        <section className="employee-overview">{employeeComponent()}</section>
      )}
      <Modal
        visible={modalProperties.visible}
        setVisible={(visible: boolean) =>
          setModalProperties((modalProperties) => ({
            ...modalProperties,
            visible,
          }))
        }
        header={modalProperties.header}
        content={modalProperties.content}
        confirmButtonText={modalProperties.confirmButtonText}
        confirmButtonAction={modalProperties.confirmButtonAction}
        showCloseButton={modalProperties.showCloseButton}
      />
    </>
  );
};

export default EmployeesOverview;
